
@import "lib/_flatpickr";
@import "lib/_nprogress";
@import "lib/_swiper";

:root {
  overflow-x: hidden;
}

.centered-slides .swiper-wrapper {
  align-items: center;
}
